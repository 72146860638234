<script setup>

  /**
   * 
   * Modal: kosár kezelése modal ablak
   * 
   * 
  */

  // nyelvi es routing beallitasok
  const localePath = useLocalePath();
  // const { locale } = useI18n();
  // const route = useRoute();

  // pinia stores
  import { storeToRefs } from 'pinia';
  import { useWebshopCartStore } from '@/store/webshopcart';
  // import { useCurrencyStore } from '@/store/currency';
  const webshopCartStore = useWebshopCartStore();

  // props
  // const { productid } = defineProps({
  //   productid: { type: String, default: null },
  // });

  // computed
  const { closeCartModal } = webshopCartStore; // all non-reactive stuff
  const { getCartModal, cartmodalloading, cartlastaddedproduct } = storeToRefs(webshopCartStore) // have all reactive states
  // let getCartmodal = computed(() => webshopCartStore.cartmodal); // ez nem az a modal
  // let cartmodalloading = computed(() => webshopCartStore.cartmodalloading);
  // let cartlastaddedproduct = computed(() => webshopCartStore.cartlastaddedproduct);

  // get project's image storage (cloudfront) url:
  const runtimeConfig = useRuntimeConfig();
  const cloudFrontDomain = runtimeConfig.public.cloudfront.domain;
  const productImagePath = '/public/images/coverstb/';
  const size = '85';
  const productImageUrl = ref(cloudFrontDomain + productImagePath + size)

  const image = computed(() => {
    // from S3 or cloudfront (see urlconfigs setup for details)
    if (
      cartlastaddedproduct.value &&
      cartlastaddedproduct.value.mainImage &&
      cartlastaddedproduct.value.mainImage !== 'notset'
    ) {
      return `${productImageUrl.value}/${cartlastaddedproduct.value.mainImage}`
    } else {
      return '/nopic.jpg'
    }
  });

</script>

<template>
  <div>
    <Transition>
    <div
      v-if="getCartModal === true"
      class="fixed top-0 left-0 w-screen h-screen bg-opacity-50 z-50 xs:px-2 sm:px-1"
      style="background-color: rgba(0, 0, 0, 0.5)"
    >
      <div
        class="xs:max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl mt-52 mx-auto bg-page-dark p-2 rounded-lg shadow-lg"
      >
        <!-- loader icon -->
        <div v-show="cartmodalloading" class="mx-auto text-center">
          <UiLoadSpinner v-show="cartmodalloading" />
        </div>
        <!-- added item info -->
        <div
          v-if="!cartmodalloading && cartlastaddedproduct !== null"
          class="flex justify-between items-center"
        >
          <!-- modal title -->
          <WebshopModalCartTitle
            v-if="
              cartlastaddedproduct &&
              cartlastaddedproduct.publicationDate &&
              cartlastaddedproduct.inventories
            "
            :inventories="cartlastaddedproduct.inventories.items"
            :publicationdate="cartlastaddedproduct.publicationDate"
          />
          <div>
            <!-- close modal -->
            <button
              type="button"
              class="w-8 h-8 p-2 bg-primary text-txt-light hover:shadow-sm items-center rounded-md font-sans font-light text-sm disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
              @click="closeCartModal()"
            >
              <font-awesome-icon :icon="['fas', 'times']" />
            </button>
          </div>
        </div>
        <div class="productdetails p-2 my-5">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <div class="m-2">
                <img
                  v-if="cartlastaddedproduct && cartlastaddedproduct.ean"
                  :src="image"
                  style="max-height: 125px; max-width: 85px"
                  class="bookimg"
                  :alt="cartlastaddedproduct.name || ''"
                  :title="cartlastaddedproduct.name || ''"
                  @error="$event.target.src = require('~/static/nopic.jpg')"
                />
              </div>
              <div class="m-2">
                <h3
                  v-if="
                    cartlastaddedproduct && cartlastaddedproduct.author
                  "
                  class="text-xs"
                >
                  <cite>{{ cartlastaddedproduct.author }}</cite>
                </h3>
                <h1
                  v-if="cartlastaddedproduct && cartlastaddedproduct.name"
                  class="text-xl leading-tight text-txt-light"
                >
                  {{ cartlastaddedproduct.name || '' }}
                </h1>
                <h2
                  v-if="
                    cartlastaddedproduct &&
                    cartlastaddedproduct.subTitle &&
                    cartlastaddedproduct.subTitle !== ''
                  "
                  class="px-2"
                >
                  {{ cartlastaddedproduct.subTitle || '' }}
                </h2>
                <div
                  v-if="cartlastaddedproduct && cartlastaddedproduct.ean"
                  class="pt-3 font-sans font-light text-xs"
                >
                  EAN:
                  <span class="text-txt-light">{{
                    cartlastaddedproduct.ean || ''
                  }}</span>
                </div>
              </div>
            </div>
            <div>
              <!-- termék ára -->
              <MoneyFormat
                v-if="
                  cartlastaddedproduct &&
                  cartlastaddedproduct.price
                "
                :icon="cartlastaddedproduct.salePercent > 0 ? 'bell' : 'tag'"
                :iconclass="
                  cartlastaddedproduct.salePercent > 0 ? 'text-red-dark' : ''
                "
                :price="cartlastaddedproduct.price"
                :currency="'HUF'"
                class="m-2 p-1"
              />
            </div>
          </div>
          <WebshopCartStockAlert
            v-if="
              cartlastaddedproduct &&
              cartlastaddedproduct.publicationDate &&
              cartlastaddedproduct.inventories
            "
            :inventories="cartlastaddedproduct.inventories.items"
            :publicationdate="cartlastaddedproduct.publicationDate"
          />
        </div>
        <!-- modal actions -->
        <div class="flex justify-between items-center">
          <div>
            <button
              type="button"
              class="text-sm focus:outline-none"
              @click="closeCartModal()"
            >
              <span class="pr-1"
                ><font-awesome-icon :icon="['fas', 'arrow-left']" /></span
              >{{ $t('Go to products') }}..
            </button>
          </div>
          <div></div>
          <div>
            <!-- to the cart -->
            <nuxt-link
              :to="localePath('my-cart')"
              class="m-2 p-2 block bg-primary text-txt-light shadow-sm hover:shadow-lg items-center rounded-md font-sans font-light focus:outline-none"
              @click="closeCartModal()"
            >
              {{ $t('Show my cart') }}
              <font-awesome-icon :icon="['fa', 'arrow-circle-right']" />
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</div>
</template>
