/**
 * 
 * Util: Globalisan elerheto egyszeru function: a rendelhetőség kalkulációjához
 * 
 *  params:
 *    - publicationdate: kiadás dátuma (0000-00-00)
 *    - inventories: egy tömb, ami az adott product raktárkészletét mutatja
 * pl:
 *      const status = calculateProductStatus('2024-10-15', [
 *        { warehouseID: '6a7528da-5096-4d0e-b864-08539b66c9fa', inventoryAmount: 10 },
 *        { warehouseID: 'other-warehouse-id', inventoryAmount: 5 }
 *       ]);
 * 
 *  TODO: Fontos!
 *    Ezt a fgv-t sokszor meghívjuk, eleinte sok hibás értékkel, amíg be nem tölt a termék, 
 *    de azt követően már a helyes értékeket adja vissza.
 *    Csinálhatnánk egy ellenőrzést erre vonatkozóan (ott, ahol meghívtuk)
 * 
*/

import { calculateProductStatus } from 'product-inventory-statuses';

export default function (publicationdate, inventories) {
  // console.log('publicationdate', publicationdate);
  // console.log('inventories', inventories);
  return calculateProductStatus(publicationdate, inventories);
}
